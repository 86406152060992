import Box from '@/views/costing/box/routes'

// import GeneralInformationCard from '@/views/costing/product/detail/components/GeneralInformationCard.vue'
// import PartListCard from '@/views/costing/part/list/PartListCard.vue'
// import BoxListCard from '@/views/costing/box/list/BoxListCard.vue'
// import ProductImageListCard from '@/views/costing/product/image/ProductImageListCard.vue'
// import ProductPartSetListCard from '@/views/costing/product-part-set/list/ProductPartSetListCard.vue'
// import ProductPartGroupListCard from '@/views/costing/product-part-group/list/ProductPartGroupListCard.vue'
// import BomCard from '@/views/costing/product/detail/components/bom/BomCard.vue'
// import BillOfProcessPage from '@/views/costing/product/detail/components/bill-of-process/BillOfProcessPage.vue'
// import NavCard from '@/views/costing/product/detail/components/nav/NavCard.vue'
// import PricingPage from '@/views/costing/product/detail/components/pricing/PricingPage.vue'
// import SummaryPage from '@/views/costing/product/detail/components/summary/SummaryPage.vue'

export default {
  path: 'product-version/:uuid',
  component: () => import('./detail/ProductDetailContainer.vue'),
  children: [
    {
      path: '',
      component: () => import('./detail/ProductDetailPage.vue'),
      children: [
        {
          path: '',
          name: 'product.detail',
          component: () =>
            import(
              '@/views/costing/product/detail/components/GeneralInformationCard.vue'
            )
        },
        {
          path: 'summary',
          name: 'product.detail.summary',
          component: () =>
            import(
              '@/views/costing/product/detail/components/summary/SummaryPage.vue'
            )
        },
        {
          path: 'pricing',
          name: 'product.detail.pricing',
          component: () =>
            import(
              '@/views/costing/product/detail/components/pricing/PricingPage.vue'
            )
        },
        {
          path: 'part',
          name: 'product.detail.part',
          component: () => import('@/views/costing/part/list/PartListCard.vue')
        },
        {
          path: 'box',
          name: 'product.detail.box',
          component: () => import('@/views/costing/box/list/BoxListCard.vue')
        },
        {
          path: 'image',
          name: 'product.detail.image',
          component: () =>
            import('@/views/costing/product/image/ProductImageListCard.vue')
        },
        {
          path: 'product-part-set',
          name: 'product.detail.product-part-set',
          component: () =>
            import(
              '@/views/costing/product-part-set/list/ProductPartSetListCard.vue'
            )
        },
        {
          path: 'product-part-group',
          name: 'product.detail.product-part-group',
          component: () =>
            import(
              '@/views/costing/product-part-group/list/ProductPartGroupListCard.vue'
            )
        },
        {
          path: 'bill-of-material',
          name: 'product.detail.bill-of-material',
          component: () =>
            import('@/views/costing/product/detail/components/bom/BomCard.vue')
        },
        {
          path: 'bill-of-process',
          name: 'product.detail.bill-of-process',
          component: () =>
            import(
              '@/views/costing/product/detail/components/bill-of-process/BillOfProcessPage.vue'
            )
        },
        {
          path: 'nav',
          name: 'product.detail.nav',
          component: () =>
            import('@/views/costing/product/detail/components/nav/NavCard.vue')
        },
        {
          path: 'nav-v2',
          name: 'product.detail.nav-v2',
          component: () =>
            import('@/views/costing/product/detail/components/nav/NavPage.vue')
        }
      ]
    },
    Box
  ]
}
