import numeral from 'numeral'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import humanizeDuration from 'humanize-duration'
import { v4 as uuidv4 } from 'uuid'

dayjs.extend(relativeTime)

export default () => {
  /**
   * Thousand digit separator
   * @param value
   * @returns string
   */
  function formatNumber(value?: number) {
    return numeral(value).format('0,0.[0000]')
  }

  /**
   * Format percentage
   * @param value
   * @returns string
   */
  function formatPercentage(value: number): string {
    // Convert the decimal to a percentage
    const percentage = value * 100

    // Format the percentage to 2 decimal places if it has decimals
    const formattedPercentage = numeral(percentage).format('0,0.[00]')

    // Append the '%' symbol
    return `${formattedPercentage}%`
  }

  /**
   * Currency format
   * @param value
   * @returns string
   */
  function formatCurrency(value?: number, limitDecimals: boolean = false) {
    if (limitDecimals) {
      return numeral(value).format('$0,0.00')
    }
    return numeral(value).format('$0,0.[0000]')
  }

  /**
   * Format Date
   * @param string
   * @returns string
   */
  function formatDate(value?: string) {
    return dayjs(value).format('DD/MM/YYYY')
  }

  /**
   * Format Date Proper
   * @param string
   * @returns string
   */
  function formatDateProper(value?: string) {
    if (value) {
      return dayjs(value).format('D MMMM YYYY')
    } else {
      return value
    }
  }

  /**
   * Format Datetime
   * @param string
   * @returns string
   */
  function formatDatetime(value?: string) {
    try {
      if (value) {
        return dayjs(value).format('DD/MM/YYYY HH:mm')
      } else {
        return value
      }
    } catch (error) {
      return ''
    }
  }

  const statusTagColor = (status: string) => {
    switch (status) {
      case 'ASSIGNED':
        return 'info'
      case 'WAITING_APPROVAL':
        return 'warning'
      case 'APPROVED':
        return 'success'
      case 'REJECTED':
        return 'danger'
      case 'SUBMITTED':
        return 'success'
      default:
        return 'info'
    }
  }

  /**
   * Format Date From Now
   * @param string
   * @returns string
   */
  function formatDateFromNow(value?: string) {
    if (value) {
      try {
        return dayjs(value).fromNow()
      } catch (error) {
        console.log(error)
        return value
      }
    } else {
      return value
    }
  }

  /**
   * Custom format date
   * @param value
   * @param format
   * @returns string
   */
  function formatCustomDate(value?: string, format?: string) {
    return dayjs(value).format(format || 'DD/MM/YYYY')
  }

  /**
   * Formats a string by splitting it by underscores, capitalizing each part, and joining them with spaces.
   *
   * @param value - The string to be formatted.
   * @returns The formatted string.
   */
  function formatProperText(value?: string): string | undefined {
    if (!value) {
      return value
    }

    // Split the string by underscores, capitalize each part, and join them with spaces
    return value
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')
  }

  /**
   * Generate image url
   * @param value
   * @returns string
   */
  function generateImageUrl(value?: string) {
    return `${
      import.meta.env.VITE_IMAGE_BASE_URL
    }/q_auto/f_auto/dpr_auto/${value}`
  }

  /**
   * Generate thumbnail url
   * @param value
   * @returns string
   */
  function generateThumbnailUrl(value?: string, width = 120) {
    return `${import.meta.env.VITE_IMAGE_BASE_URL}/c_thumb,w_${width}/${value}`
  }

  /**
   * Convert ENUM to Text
   * @param enumList
   * @param value
   * @returns string
   */
  function convertEnum(enumList: Array<any>, value: string) {
    const found = enumList.find((item: any) => item.id === value)
    if (found) {
      return found.name
    } else {
      return value
    }
  }

  /**
   * Thousand digit separator
   * @param value
   * @returns string
   */
  function formatDuration(value: number) {
    const second = value * 1000
    return humanizeDuration(second)
  }

  /**
   * Converts a given number of seconds into a human-readable string format.
   *
   * @param value - The number of seconds to convert.
   * @returns A string representing the time in hours, minutes, or seconds.
   *          For example, "1 hour", "2 hours", "1 minute", "2 minutes", "1 second", "2 seconds".
   */
  function convertSeconds(value: number): string {
    if (value >= 3600) {
      const hours = value / 3600
      const formattedHours = parseFloat(hours.toFixed(1))
      return `${formattedHours} hour${formattedHours !== 1 ? 's' : ''}`
    } else if (value >= 60) {
      const minutes = value / 60
      const formattedMinutes = parseFloat(minutes.toFixed(1))
      return `${formattedMinutes} minute${formattedMinutes !== 1 ? 's' : ''}`
    } else {
      return `${value} second${value !== 1 ? 's' : ''}`
    }
  }

  /**
   * Format second
   * @param value
   * @returns string
   */
  function formatSecond(value: number) {
    return `${formatNumber(value)} second`
  }

  /**
   * Format second to minute
   * @param value
   * @returns string
   */
  function formatMinute(value: number) {
    return `${formatNumber(value / 60)} minute`
  }

  /**
   * Format second to minute
   * @param value
   * @returns string
   */
  function calculateAge(birthdate: string | undefined): number {
    if (birthdate) {
      const birthDate = dayjs(birthdate)
      const currentDate = dayjs()
      const age = currentDate.diff(birthDate, 'year')
      return age
    } else {
      return 0
    }
  }

  /**
   * Retrieves the error messages from an array of errors.
   * @param errors - The array of errors.
   * @returns The error messages as a string.
   */
  const getErrors = (errors: any) => {
    if (errors.length > 0) {
      return errors[0].$message
    }
    return ''
  }

  // Formatter function
  const formatNumberWithCommas = (value: number) => {
    // Convert the number to a string and split it into integer and decimal parts
    const [integerPart, decimalPart] = `${value}`.split('.')

    // Format the integer part with commas
    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ','
    )

    // If there is a decimal part, join it back with the integer part
    if (decimalPart) {
      return `${formattedIntegerPart}.${decimalPart}`
    }

    // Otherwise, return the formatted integer part
    return formattedIntegerPart
  }

  // Parser function
  const removeCommas = (value: string) => value.replace(/,/g, '')

  // Generate UUID
  const generateUuid = () => uuidv4()

  return {
    calculateAge,
    convertEnum,
    formatCurrency,
    formatCustomDate,
    formatDate,
    formatDateFromNow,
    formatDateProper,
    formatDatetime,
    formatDuration,
    formatMinute,
    formatNumber,
    formatPercentage,
    formatNumberWithCommas,
    formatProperText,
    formatSecond,
    statusTagColor,
    generateImageUrl,
    generateThumbnailUrl,
    generateUuid,
    getErrors,
    removeCommas,
    convertSeconds
  }
}
